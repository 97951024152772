.animateButton {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	/* line-height: 2; */
	color: #ffdd05;
	font-size: 30px;
	font-family: verdana;
    letter-spacing: 4px;
    width: 100px;

	/* use combination of any two */
	/* 1. */
	/* padding: 5px; */

	/* 2. */
	padding-left: 5px;
	/* padding-right: 5px; */
}

.animateButton::before,
.animateButton:after,
.spanAnimateButton::before,
.spanAnimateButton::after {
    content: '';
    color: white;
	position: absolute;
	width: 8px;
	height: 8px;
	background: transparent;
	transition: 1s;
	/* -webkit-mix-blend-mode: hue; */
}

.animateButton::before {
	top: -2px;
	left: -2px;
	border-top: 2px solid #ffdd05;
	border-left: 2px solid #ffdd05;
}
.animateButton::after {
	top: -2px;
	right: -2px;
	border-top: 2px solid #ffdd05;
	border-right: 2px solid #ffdd05;
}

.spanAnimateButton::before {
    color: white;
	bottom: -2px;
	left: -2px;
	border-bottom: 2px solid #ffdd05;
	border-left: 2px solid #ffdd05;
}
.spanAnimateButton::after {
	bottom: -2px;
    right: -2px;
    color: #343a40;
	border-bottom: 2px solid #ffdd05;
	border-right: 2px solid #ffdd05;
}


.animateButton:hover::before,
.animateButton:hover::after,
.animateButton:hover span::before,
.animateButton:hover span::after  {
	width: 200px;
    height: calc( 50px / 2);
    background-color: #ffdd05;
    z-index: -1; 
}

.imageMod {
    object-fit: cover;
    max-height: 500px;
    width: 100%;
}
/* span{
    color: #343a40
} */

.imageBackground {
    max-width: 1920px;
    width: 100%;
    margin-top: 70px;
    
}


.customToolbarHeader {
	-webkit-animation-name: customToolbarHeader;
	animation-name: customToolbarHeader;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
	-webkit-animation-duration: 2s;
	-moz-animation-duration: 2s;
	-o-animation-duration: 2s;
	-ms-animation-duration: 2s;
	animation-duration: 2s;
  }


  @-webkit-keyframes customToolbarHeader {
	0% {
	  opacity: 0;
	  -moz-transform: translateY(20px);
	  -o-transform: translateY(20px);
	  -ms-transform: translateY(20px);
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	}
	100% {
	  opacity: 1;
	  -moz-transform: none;
	  -o-transform: none;
	  -ms-transform: none;
	  -webkit-transform: none;
	  transform: none;
	}
  }
  @-moz-keyframes customToolbarHeader {
	0% {
	  opacity: 0;
	  -moz-transform: translateY(20px);
	  -o-transform: translateY(20px);
	  -ms-transform: translateY(20px);
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	}
	100% {
	  opacity: 1;
	  -moz-transform: none;
	  -o-transform: none;
	  -ms-transform: none;
	  -webkit-transform: none;
	  transform: none;
	}
  }
  @-o-keyframes customToolbarHeader {
	0% {
	  opacity: 0;
	  -moz-transform: translateY(20px);
	  -o-transform: translateY(20px);
	  -ms-transform: translateY(20px);
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	}
	100% {
	  opacity: 1;
	  -moz-transform: none;
	  -o-transform: none;
	  -ms-transform: none;
	  -webkit-transform: none;
	  transform: none;
	}
  }
  @keyframes customToolbarHeader {
	0% {
	  opacity: 0;
	  -moz-transform: translateY(20px);
	  -o-transform: translateY(20px);
	  -ms-transform: translateY(20px);
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	}
	100% {
	  opacity: 1;
	  -moz-transform: none;
	  -o-transform: none;
	  -ms-transform: none;
	  -webkit-transform: none;
	  transform: none;
	}
  }
  

  @media (max-width: 760px) {
	  .customToolbarHeader{
	  display: none;
	  }
  }
  .flexRow {
	display: flex;
	flex: 1;
	flex-direction: row;
}

  .adjustableToolBar {
	justify-content: 'flex-end'; display: 'flex'; flex: 1;
  }

  @media (max-width: 760px) {
	.adjustableToolBar{
		display: inline-table;
		width: 100%
	}
	.flexRow {
		flex-direction: column;
		display: flex;
		flex: 1;
	}
}

