.nav-link-text {
  color: rgb(170, 170, 170);
  padding: 10px;
  margin: 5px
}

.nav-link-text-active{
  color: rgb(170, 170, 170);
  padding: 8px;
  font-size: smaller
}

